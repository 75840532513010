var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    [
      _c(
        "avue-crud",
        {
          ref: "crud",
          attrs: {
            option: _vm.option,
            "table-loading": _vm.loading,
            data: _vm.data,
            permission: _vm.permissionList,
            "before-open": _vm.beforeOpen,
            search: _vm.query,
          },
          on: {
            "row-del": _vm.rowDel,
            "row-update": _vm.rowUpdate,
            "row-save": _vm.rowSave,
            "search-change": _vm.searchChange,
            "search-reset": _vm.searchReset,
            "selection-change": _vm.selectionChange,
            select: _vm.handleRowSelect,
            "select-all": _vm.hanldeSelectAll,
            "current-change": _vm.currentChange,
            "size-change": _vm.sizeChange,
            "refresh-change": _vm.refreshChange,
            "on-load": _vm.onLoad,
            "update:search": function ($event) {
              _vm.query = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "menu",
              fn: function (scope) {
                return [
                  _vm.permission.role_view && scope.row.isSystem !== 1
                    ? _c(
                        "el-button",
                        {
                          staticClass: "none-border",
                          attrs: { type: "text", size: "small", plain: "" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.viewDetail(scope.row)
                            },
                          },
                        },
                        [_vm._v("查看\n      ")]
                      )
                    : _vm._e(),
                  _vm.permission.role_view && scope.row.isSystem !== 1
                    ? _c(
                        "el-button",
                        {
                          staticClass: "none-border",
                          attrs: { type: "text", size: "small", plain: "" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.rowEdit(scope.row)
                            },
                          },
                        },
                        [_vm._v("编辑\n      ")]
                      )
                    : _vm._e(),
                  _vm.permission.role_view && scope.row.isSystem !== 1
                    ? _c(
                        "el-button",
                        {
                          staticClass: "none-border",
                          attrs: { type: "text", size: "small", plain: "" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.rowDel(scope.row)
                            },
                          },
                        },
                        [_vm._v("删除\n      ")]
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "template",
            { slot: "menuLeft" },
            [
              _vm.userInfo.role_name.includes("admin")
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        icon: "el-icon-setting",
                        plain: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleRole()
                        },
                      },
                    },
                    [_vm._v("权限设置\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "角色权限配置",
            "append-to-body": "",
            visible: _vm.box,
            width: "450px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.box = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            { attrs: { type: "border-card" } },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "菜单权限" } },
                [
                  _c("el-tree", {
                    ref: "treeMenu",
                    attrs: {
                      data: _vm.menuGrantList,
                      "show-checkbox": "",
                      "node-key": "id",
                      "default-checked-keys": _vm.menuTreeObj,
                      props: _vm.props,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "数据权限" } },
                [
                  _c("el-tree", {
                    ref: "treeDataScope",
                    attrs: {
                      data: _vm.dataScopeGrantList,
                      "show-checkbox": "",
                      "node-key": "id",
                      "default-checked-keys": _vm.dataScopeTreeObj,
                      props: _vm.props,
                    },
                    on: { check: _vm.dataScopeCheck },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.box = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "角色详细",
            "append-to-body": "",
            visible: _vm.roleDetailDialog,
            width: "345px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.roleDetailDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "role-info" }, [
            _c("p", { staticClass: "role-info-item" }, [
              _vm._v("角色名称: " + _vm._s(_vm.dialogData.roleName)),
            ]),
            _c("p", { staticClass: "role-info-item" }, [
              _vm._v("是否是系统角色: " + _vm._s(_vm.dialogData.$isSystem)),
            ]),
            _c("p", { staticClass: "role-info-item" }, [
              _vm._v("角色排序: " + _vm._s(_vm.dialogData.sort)),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "角色编辑",
            "append-to-body": "",
            visible: _vm.roleEditDialog,
            width: "345px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.roleEditDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.roleRlues,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "角色名称",
                    prop: "roleName",
                    rules: [{ required: true, message: "角色名称必填" }],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入角色名称" },
                    model: {
                      value: _vm.form.roleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "roleName", $$v)
                      },
                      expression: "form.roleName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上级角色", prop: "parentId" } },
                [
                  _c("select-tree", {
                    attrs: { data: _vm.prarentOptions },
                    model: {
                      value: _vm.form.parentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "parentId", $$v)
                      },
                      expression: "form.parentId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "排序",
                    prop: "sort",
                    rules: [{ required: true, message: "角色排序必填" }],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "角色排序" },
                    model: {
                      value: _vm.form.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort", $$v)
                      },
                      expression: "form.sort",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitRole } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }