<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      ref="crud"
      v-model="form"
      :permission="permissionList"
      :before-open="beforeOpen"
      @row-del="rowDel"
      @row-update="rowUpdate"
      @row-save="rowSave"
      @search-change="searchChange"
      @search-reset="searchReset"
      @selection-change="selectionChange"
      @select="handleRowSelect"
      @select-all="hanldeSelectAll"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @on-load="onLoad"
      :search.sync="query"
    >
      <template slot="menuLeft">
        <!-- <el-button
          type="danger"
          size="small"
          icon="el-icon-delete"
          v-if="permission.role_delete"
          plain
          @click="handleDelete"
          >删 除
        </el-button> -->
        <el-button
          size="small"
          icon="el-icon-setting"
          @click="handleRole()"
          v-if="userInfo.role_name.includes('admin')"
          plain
          >权限设置
        </el-button>
      </template>

      <template slot-scope="scope" slot="menu">
        <el-button type="text"
                   size="small"
                  v-if="permission.role_view && scope.row.isSystem!==1"
                   plain
                   class="none-border"
                   @click.stop="viewDetail(scope.row)">查看
        </el-button>

        <el-button type="text"
                   size="small"
                   v-if="permission.role_view && scope.row.isSystem!==1"
                   plain
                   class="none-border"
                   @click.stop="rowEdit(scope.row)">编辑
        </el-button>

        <el-button type="text"
                   size="small"
                  v-if="permission.role_view && scope.row.isSystem!==1"
                   plain
                   class="none-border"
                   @click.stop="rowDel(scope.row)">删除
        </el-button>
      </template>


    </avue-crud>
    <!-- 权限设置 -->
    <el-dialog
      title="角色权限配置"
      append-to-body
      :visible.sync="box"
      width="450px"
    >
      <el-tabs type="border-card">
        <el-tab-pane label="菜单权限">
          <el-tree
            :data="menuGrantList"
            show-checkbox
            node-key="id"
            ref="treeMenu"
            :default-checked-keys="menuTreeObj"
            :props="props"
          >
          </el-tree>
        </el-tab-pane>
        <el-tab-pane label="数据权限">
          <el-tree
            :data="dataScopeGrantList"
            show-checkbox
            node-key="id"
            ref="treeDataScope"
            :default-checked-keys="dataScopeTreeObj"
            @check="dataScopeCheck"
            :props="props"
          >
          </el-tree>
        </el-tab-pane>
        <!-- <el-tab-pane label="接口权限">
          <el-tree
            :data="apiScopeGrantList"
            show-checkbox
            node-key="id"
            ref="treeApiScope"
            :default-checked-keys="apiScopeTreeObj"
            :props="props"
          >
          </el-tree>
        </el-tab-pane> -->
      </el-tabs>

      <span slot="footer" class="dialog-footer">
        <el-button @click="box = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 角色查看 -->
    <el-dialog
      title="角色详细"
      append-to-body
      :visible.sync="roleDetailDialog"
      width="345px"
      >
      <div class="role-info">
          <p class="role-info-item">角色名称: {{ dialogData.roleName }}</p>
          <p class="role-info-item">是否是系统角色: {{ dialogData.$isSystem }}</p>
          <p class="role-info-item">角色排序: {{ dialogData.sort }}</p>
      </div>
    </el-dialog>
    <!-- 角色编辑 -->
    <el-dialog
      title="角色编辑"
      append-to-body
      :visible.sync="roleEditDialog"
      width="345px"
      >
      <el-form :model="form"
        ref="form"
        :rules="roleRlues"
        label-width="80px">
        <el-form-item label="角色名称"
                      prop="roleName"
                      :rules="[{ required: true, message: '角色名称必填'}]">
          <el-input v-model="form.roleName"
                    placeholder="请输入角色名称" />
        </el-form-item>
        <el-form-item 
          label="上级角色"
          prop="parentId">
          <select-tree
            :data="prarentOptions"
            v-model="form.parentId">
          </select-tree>
              <!-- <el-cascader
                v-model="form.parentId"
                placeholder="请选择"
                :options="prarentOptions"
                :props="{ 
                  checkStrictly: true,
                  expandTrigger: 'hover'
                }"
                filterable>
                <template slot-scope="{ node, data }">
                  <span>{{ data.label }}</span>
                </template>
              </el-cascader> -->
        </el-form-item>

        <el-form-item label="排序"
                      prop="sort"
                      :rules="[{ required: true, message: '角色排序必填'}]">
          <el-input v-model="form.sort"
                    placeholder="角色排序" />
        </el-form-item>

      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                    @click="submitRole">确 定</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>

<script>
import {
  add,
  getList,
  getRole,
  getRoleTreeById,
  grant,
  grantTree,
  remove,
  update,
  getRoleTreeByApp
} from "@/api/system/role";
import { mapGetters } from "vuex";

export default {
  name: 'role-manager',
  data() {
    return {
      form: {},
      box: false,
      props: {
        label: "title",
        value: "key",
      },
      prarentOptions:[],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      dialogData: {},
      roleEditDialog: false,
      menuGrantList: [],
      dataScopeGrantList: [],
      apiScopeGrantList: [],
      apiGrantList: [],
      menuTreeObj: [],
      dataScopeTreeObj: [],
      apiScopeTreeObj: [],
      selectionList: [],
      query: { app: null },
      loading: true,
      roleDetailDialog: false,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      roleRlues: {
        roleName: [{ required: true, message: "请输入角色名称", trigger: "blur" }],
        sort: [{ required: true, message: "请输入角色名称", trigger: "blur" }],
      },
      option: {
        height:'auto',
        calcHeight: 10,
        tip: false,
        simplePage: true,
        searchShow: true,
        searchMenuSpan: 6,
        tree: true,
        border: true,
        index: true,
        selection: true,
        editBtn: false,
        viewBtn: false,
        delBtn: false,
        dialogWidth: 900,
        searchBtnText: "搜索",
        dialogClickModal: false,
        column: [
          {
            label: "角色名称",
            prop: "roleName",
            search: true,
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入角色名称",
                trigger: "blur",
              },
            ],
          },
          // {
          //   label: "角色别名",
          //   prop: "roleAlias",
          //   search: true,
          //   span: 24,
          //   rules: [
          //     {
          //       required: true,
          //       message: "请输入角色别名",
          //       trigger: "blur",
          //     },
          //   ],
          // },

          {
              label: "是否系统预置角色",
              prop: "isSystem",
              type: "switch",
              align: "center",
              width: 80,
              addDisplay: false,
              dicData: [
                {
                  label: "否",
                  value: 0
                },
                {
                  label: "是",
                  value: 1
                }
              ],
              value: 0,
              slot: true,
              rules: [
                {
                  required: true,
                  message: "请选择是否覆盖",
                  trigger: "blur"
                }
              ]
          },
          {
            label: "上级角色",
            prop: "parentId",
            // prop: "parentName",
            // dicData: [],
            dicUrl: "/api/blade-system/role/tree-by-id?app=aos-schedule",
            type: "tree",
            hide: true,
            span: 24,
            props: {
              label: "title",
            },
            rules: [
              {
                required: false,
                message: "请选择上级角色",
                trigger: "click",
              },
            ],
          },
          {
            label: "角色排序",
            prop: "sort",
            type: "number",
            precision: "0",
            step: "1",
            min: "0",
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入角色排序",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      data: [],
    };
  },

  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
  
        addBtn: this.vaildData(this.permission.role_add, false),
        viewBtn: this.vaildData(this.permission.role_view, false),
        delBtn: this.vaildData(this.permission.role_delete, false),
        editBtn: this.vaildData(this.permission.role_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    idsArray() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids;
    },
  },
  methods: {
    initData(roleId) {
      getRoleTreeById(roleId).then((res) => {
        const column = this.findObject(this.option.column, "parentId");
        column.dicData = res.data.data;
      });
    },

    viewDetail(row){
      this.roleDetailDialog=true;
      this.dialogData=row;
    },

    rowEdit(row){
      this.roleEditDialog = true;
      this.form = JSON.parse(JSON.stringify(row)) ;
      getRoleTreeByApp().then((res) => {
          const result = this.filterListData(res.data.data)
          this.prarentOptions = result;
      });
    },

    filterListData(arr) {
      let res = []
      for (var i = 0; i < arr.length; i++) {
        let item = arr[i]
        res.push(
            {
              label: item.title,
              value: item.value,
              children: (item.children && Array.isArray(item.children) && item.children.length > 0)? 
                this.filterListData(item.children): undefined
            }
        )
      }
      return res
    },

    submit() {
      const menuList = this.$refs.treeMenu.getCheckedKeys();
      const dataScopeList = this.$refs.treeDataScope.getCheckedKeys();
      // const apiScopeList = this.$refs.treeApiScope.getCheckedKeys();
      grant(this.idsArray, menuList, dataScopeList, []).then(() => {
        this.box = false;
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.onLoad(this.page);
      });
    },
    rowSave(row, done, loading) {
      row.app="aos-schedule";
      add(row).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          console.error(error);
          loading();
        }
      );
    },
    rowUpdate(row, index, done, loading) {
      update(row).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          console.error(error);
          loading();
        }
      );
    },

    submitRole(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: "角色保存中...请稍后...",
            spinner: "el-icon-loading",
          });
          update(this.form)
            .then((res) => {
              if (res.data.code === 200) {
                this.$message.success("角色修改成功！");
                loading.close();
                this.roleEditDialog=false;
                setTimeout(() => {
                  this.$emit("goFlow", "login");
                }, 1000);

                this.refreshChange();
              }
            })
            .catch((err) => {
              loading.close();
              this.$message.error(err);
            });
        }
      });

    },

    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },

    searchReset() {
      this.query = { app: null };
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },

    hanldeSelectAll(selection) {
      var LevelOneIds = [];
      this.data.forEach((ele) => {
        LevelOneIds.push(ele.id);
      });

      var SelectedIds = [];
      selection.forEach((ele) => {
        SelectedIds.push(ele.id);
      });

      LevelOneIds = LevelOneIds.join(",");
      SelectedIds = SelectedIds.join(",");

      if (LevelOneIds === SelectedIds) {
        let arr = this.filterSelect(selection);
        if (arr) {
          arr.forEach((row) => {
            this.$refs.crud.toggleRowSelection(row, true);
          });
        }
      } else {
        // this.$refs.crud.toggleAllSelection();
        this.$nextTick(() => {
          this.selectionList.forEach((row) => {
            this.$refs.crud.toggleRowSelection(row, false);
          });
        });
      }
    },
    handleRowSelect(selection) {
      let arr = this.filterSelect(selection);
      if (arr) {
        arr.forEach((row) => {
          this.$refs.crud.toggleRowSelection(row, true);
        });
      }
    },

    filterSelect(selection) {
      let arr = [];
      function filterData(val) {
        arr.push(val);
        if (val.children) {
          val.children.map((item) => {
            filterData(item);
          });
        }
      }
      selection.map((val) => {
        filterData(val);
      });
      return arr;
    },
    selectionChange(selection) {
      this.selectionList = selection;
    },

    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },

    beforeOpen(done, type) {
      if (["add", "edit"].includes(type)) {
        this.initData(this.form.id);
      }
      done();
    },

    handleRole() {
      if (this.selectionList.length !== 1) {
        this.$message.warning("只能选择一条数据");
        return;
      }
      this.menuTreeObj = [];
      this.dataScopeTreeObj = [];
      this.apiScopeTreeObj = [];
      const search = {
        app: "aos-schedule",
      };

      //遍历数据，如果是系统内置角色，则加disabled
      grantTree(search).then((res) => {
        // 权限设置列表
        this.menuGrantList = res.data.data.menu;
        this.dataScopeGrantList = res.data.data.dataScope;
        this.apiScopeGrantList = res.data.data.apiScope;
        // 预制角色权限设置禁用
        if(this.selectionList[0].isSystem === 1){
          this.menuGrantList.forEach(item => {
            this.setDisabled(item);
          })
          this.dataScopeGrantList.forEach(item => {
            this.setDisabled(item);
          })
        }
        // 数据权变满足有且只有一个
        this.dataScopeGrantList.forEach(item => {
          item.disabled = true
        })
        getRole(this.ids).then((res) => {
          this.menuTreeObj = res.data.data.menu;
          this.dataScopeTreeObj = res.data.data.dataScope;
          this.apiScopeTreeObj = res.data.data.apiScope;
          this.box = true;
        });
      });
    },

    setDisabled(node) {
      // 设置节点的 disabled 属性
      node.disabled = true; // 根据具体需求设置 disabled 属性的值
      if (node.hasChildren) {
        node.children.forEach(child => {
          this.setDisabled(child);
        });
      }
    },

    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }


      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$refs.crud.toggleSelection();
        });
    },

    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },

    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },

    refreshChange() {
      this.onLoad(this.page, this.query);
    },

    onLoad(page, params = {}) {
      this.loading = true;
      // getAppList(1, 100).then((res) => {
      //   const data = res.data.data.records;
      //   if (data.length !== 0) {
      //     if (this.query.app === null) {
      //       this.query.app = data[0].clientId;
      //       this.option.column[0].searchValue = data[0].name;
      //     }
      this.query.app="aos-schedule";
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        this.data = res.data.data;
        this.loading = false;
        this.selectionClear();
      });
    },
    // 数据权限check时
    dataScopeCheck(data, checks) {
      this.constraintDataScopeCheck(data, checks)
    },
    // 对数据权限选项进行约束
    constraintDataScopeCheck (data, checks) {
      let currentParentId = data.parentId
      const checkedNodes = checks.checkedNodes
      for (var i = 0; i < checkedNodes.length; i++){
        const node = checkedNodes[i]
        if (node.parentId && node.parentId === currentParentId && node.key !== data.key) {
          this.$refs.treeDataScope.setChecked(node.key, false)
        }
      }
    }
  },
};
</script>
